<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="完成出殡"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <span style="margin-left:50px;">确定完成出殡仪式吗？请设置仪式完成后任务负责人：</span>
        <a-form-item label="花房撤花负责人">
          <a-select
            v-decorator="['flower_room_user_id', {
              initialValue: defaultFlowerRoomUser.id > 0 ? defaultFlowerRoomUser.id : undefined,
              rules: [
                { required: true, message: '请选择花房撤花负责人' }
              ]
            }]"
            placeholder="请选择花房撤花负责人"
            @load="loadingFlowerRoomUserOptions"
            @change="handleFlowerRoomChange"
          >
            <a-select-option
              v-for="user in flowerRoomUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['flower_room_phone_number', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="一线撤花负责人">
          <a-select
            v-decorator="['front_flower_user_id', {
              initialValue: defaultFrontFlowerUser.id > 0 ? defaultFrontFlowerUser.id : undefined,
              rules: [
                { required: true, message: '请选择一线撤花负责人' }
              ]
            }]"
            placeholder="请选择一线撤花负责人"
            @load="loadingFrontFlowerUserOptions"
            @change="handleFrontFlowerChange"
          >
            <a-select-option
              v-for="user in frontFlowerUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['front_flower_phone_number', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="厅房保洁负责人">
          <a-select
            v-decorator="['hall_clean_user_id', {
              initialValue: defaultHallCleanUser.id > 0 ? defaultHallCleanUser.id : undefined,
              rules: [
                { required: true, message: '请选择厅房保洁负责人' }
              ]
            }]"
            placeholder="请选择厅房保洁负责人"
            @load="loadingHallCleanUserOptions"
            @change="handleHallCleanChange"
          >
            <a-select-option
              v-for="user in hallCleanUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['hall_clean_phone_number', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { finishHoldFuneralCeremony } from '@/api/order'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'FinishHoldFuneralCeremony',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'finish_ceremony' }),
      submitting: false,
      flowerRoomUserOptions: [],
      loadingUserOptions: false,
      loadingFlowerRoomUserOptions: false,
      frontFlowerUserOptions: [],
      loadingFrontFlowerUserOptions: false,
      hallCleanUserOptions: [],
      loadingHallCleanUserOptions: false,
      data: {},
      defaultFlowerRoomUser: {},
      defaultFrontFlowerUser: {},
      defaultHallCleanUser: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFrontFlowerUserOptions()
    this.fetchFlowerRoomUserOptions()
    this.fetchHallCleanUserOptions()
  },
  methods: {
    handleFlowerRoomChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ flower_room_phone_number: res.data.phone_number })
        }
      })
    },
    handleFrontFlowerChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ front_flower_phone_number: res.data.phone_number })
        }
      })
    },
    handleHallCleanChange(e) {
      findUser(e).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ hall_clean_phone_number: res.data.phone_number })
        }
      })
    },
    fetchFlowerRoomUserOptions() {
      this.loadingFlowerRoomUserOptions = true
      findTenantTaskUserOptions({ task_type: 'flower_withdrawal', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.flowerRoomUserOptions = res.data.option_list
          this.defaultFlowerRoomUser = res.data.default_user
          this.form.setFieldsValue({ flower_room_phone_number: this.defaultFlowerRoomUser.phone_number })
        }
        this.loadingFlowerRoomUserOptions = false
      })
    },
    fetchFrontFlowerUserOptions() {
      this.loadingFrontFlowerUserOptions = true
      findTenantTaskUserOptions({ task_type: 'front_line_flower_withdrawal', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.frontFlowerUserOptions = res.data.option_list
          this.defaultFrontFlowerUser = res.data.default_user
          this.form.setFieldsValue({ front_flower_phone_number: this.defaultFrontFlowerUser.phone_number })
        }
        this.loadingFrontFlowerUserOptions = false
      })
    },
    fetchHallCleanUserOptions() {
      this.loadingHallCleanUserOptions = true
      findTenantTaskUserOptions({ task_type: 'hall_clean', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.hallCleanUserOptions = res.data.option_list
          this.defaultHallCleanUser = res.data.default_user
          this.form.setFieldsValue({ hall_clean_phone_number: this.defaultHallCleanUser.phone_number })
        }
        this.loadingHallCleanUserOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          finishHoldFuneralCeremony(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
